import { 
  Component, 
  ComponentRef, 
  Inject, 
  OnDestroy, 
  OnInit, 
  TemplateRef, 
  ViewChild, 
  ViewContainerRef,
  AfterViewInit,
  EmbeddedViewRef,
} from '@angular/core';

import { 
  Router,
  ActivatedRoute, 
  ParamMap 
} from '@angular/router';

import { Location } from '@angular/common';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {Title} from "@angular/platform-browser";

import { 
  MsalInterceptorConfiguration, 
  MsalService, 
  MSAL_INTERCEPTOR_CONFIG 
} from '@azure/msal-angular';


import { 
  AccountInfo, 
  EndSessionRequest 
} from '@azure/msal-browser';

import { LoadingBarService } from '@ngx-loading-bar/core';

import { isNil, isObject } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexErrorService } from './error/datex-error-service';
import { protectedResources } from './auth-config';
import { ShellService } from './shell.service';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService, EModalSize, EToasterType, EToasterPosition } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './app.frontend.types'
import { $frontendTypes as $types} from './app.frontend.types' 


interface IMenubarItem {
  id: string;
  icon: string;
  label: string;
  parent?: IMenubarItem;
  items?: IMenubarItem[],
  menubar?: any;
  hidden?: boolean;
  click?: ($utils: UtilsService) => void;
  onChildHidden?: (item: IMenubarItem) => void;
  onParentHidden?: (item: IMenubarItem) => void;
  getOwnHidden?(): boolean;
}

class MenuItemModel implements IMenubarItem {
  id: string;
  icon: string;
  label: string;
  parent?: IMenubarItem;
  items: IMenubarItem[] = [];
  menubar: any;
  click?: ($utils: UtilsService) => void;
  private _hidden: boolean = false;
  private _onMenuHidden: () => void;

  constructor(id: string, icon: string, label: string, items: IMenubarItem[], click: ($utils: UtilsService) => void, onMenuHidden: () => void) {
    this.id = id;
    this.icon = icon;
    this.label = label;
    this.items = items;
    this.items?.forEach(i => i.parent = this);
    this.menubar = items ? Object.fromEntries(items.map(i => [i.id, i]) ?? []) : null;
    this.click = click;
    this._onMenuHidden = onMenuHidden;
  }

  onParentHidden(item: IMenubarItem){
    this._hidden = item.hidden;
    if (this._hidden) {
      this._onMenuHidden();
    }
  }

  onChildHidden(item: IMenubarItem){
    this._hidden = this.items.every(i => i.getOwnHidden());
    if (this._hidden) {
      this._onMenuHidden();
    }
  }

  get hidden(): boolean{
    return this._hidden || this.parent?.hidden || false;
  }

  set hidden(val: boolean){
    this._hidden = val;
    this.parent?.onChildHidden(this);
    this.items?.forEach(i => i.onParentHidden(this));
  }

  getOwnHidden(): boolean {
    return this._hidden;
  }
}

class Crumb {
  destroyed$ = new Subject();

  title: string;
  referenceName: string;
  active: boolean;
  queryString: string;

  componentRef: ComponentRef<any>;
  closeToolRef: EmbeddedViewRef<any>;

  constructor(
    title: string,
    referenceName: string,
    component: ComponentRef<any>,
    closeTool: EmbeddedViewRef<any>,
    inParams: any
  ) {
    this.title = title;
    this.referenceName = referenceName;
    this.componentRef = component;
    this.closeToolRef = closeTool;

    if (inParams) {
      this.queryString = Object.keys(inParams).map((key) => {
        const paramValue = inParams[key];
        // Will stringify if obj or array
        if (isObject(paramValue)) {
          return `${key}=${encodeURIComponent(JSON.stringify(paramValue))}`;
        } else {
          return `${key}=${encodeURIComponent(paramValue)}`;
        }
      }).join('&');
    }
  }

  destroy() {
    this.componentRef.destroy();
    if (this.closeToolRef) {
      this.closeToolRef.destroy();
    }
    this.destroyed$.next(null);
  }
}

enum EmbedScriptType {
  Inline,
  External
}

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  providers: [
  ]
})
export class ShellComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('closeTool', { read: TemplateRef }) 
  closeToolTmpRef: TemplateRef<any>;
  
  @ViewChild("host", { read: ViewContainerRef }) 
  hostRef: ViewContainerRef;


  _title: string = "Footprint";
  _logo: string = "assets/img/footprint_2024_icon_white.svg";

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
    this.titleService.setTitle(this._title);
  }

  get logo(): string {
    return this._logo;
  }

  set logo(value: string) {
    this._logo = value;
    const links = document.getElementsByTagName("link");
    for(var i = 0; i < links.length; i++) {
      if (links[i].rel === "icon") {
        links[i].href = this._logo;
        links[i].type = undefined;
      }
    };
  }

  account: AccountInfo;
  showMainMenu = true;
  currentMenubarItem: IMenubarItem = null;
  homeMenubarItem: IMenubarItem = {
    id: 'home',
    label: 'Home',
    icon: 'icon-ic_fluent_home_20_regular',
  }

  items: IMenubarItem[] = [
  
    new MenuItemModel('dashboards', 'icon-ic_fluent_arrow_trending_lines_20_regular', 'Dashboards', [
        new MenuItemModel('revenue_dashboard', 'ms-Icon ms-Icon--CostContralLedgerAdmin', 'Revenue', [],  ($utils: UtilsService) => {
            this.shell.openrevenue_powerbi(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('lot_management_dashboard', 'icon-ic_fluent_content_settings_20_regular', 'Lot Management', [],  ($utils: UtilsService) => {
            this.shell.openlot_management_powerbi(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('labor_management_dashboard', 'icon-ic_fluent_people_team_toolbox_20_regular', 'Labor', [],  ($utils: UtilsService) => {
            this.shell.openlabor_management_powerbi(
            false)},
            () => this.currentMenubarItem = null
      ),
      ]
      ,      
      ($utils: UtilsService) => {},
      () => this.currentMenubarItem = null
    ),
  
    new MenuItemModel('inbound', 'icon-ic_fluent_arrow_enter_20_regular', 'Inbound', [
        new MenuItemModel('inbound_orders', 'ms-Icon ms-Icon--DecreaseIndent', 'Inbound Orders', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openinbound_orders_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('returns_hub', 'icon-ic_fluent_arrow_undo_20_regular', 'Returns', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openreturns_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
      ]
      ,      
      ($utils: UtilsService) => {},
      () => this.currentMenubarItem = null
    ),
  
    new MenuItemModel('outbound', 'icon-ic_fluent_arrow_exit_20_regular', 'Outbound', [
        new MenuItemModel('outbound_orders', 'ms-Icon ms-Icon--DecreaseIndentMirrored', 'Outbound Orders', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openoutbound_orders_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('warehouse_transfers', 'icon-ic_fluent_building_swap_20_regular', 'Warehouse Transfers', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openwarehouse_transfers_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('pack_verification', 'icon-ic_fluent_barcode_scanner_20_regular', 'Pack Verification', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openpack_verification_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('waves', 'icon-ic_fluent_clipboard_pulse_20_regular', 'Waves', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openwaves_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
      ]
      ,      
      ($utils: UtilsService) => {},
      () => this.currentMenubarItem = null
    ),
  
    new MenuItemModel('transportation', 'icon-ic_fluent_vehicle_truck_profile_20_regular', 'Transportation', [
        new MenuItemModel('dock_appointments', 'ms-Icon ms-Icon--Calendar', 'Dock Appointments', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.opendock_appointments_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('dock_doors', 'icon-ic_fluent_door_20_regular', 'Dock Doors', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.opendock_doors_hub(
            {
              warehouseIds:  null 
            },
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('driver_check_out', 'icon-ic_fluent_signature_20_regular', 'Driver Check Out', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.opendriver_check_out_hub(
            {
              orderId:  null 
            },
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('load_containers', 'icon-ic_fluent_row_triple_20_regular', 'Load Containers', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openload_containers_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('shipping_containers', 'icon-ic_fluent_layout_column_two_split_left_20_regular', 'Shipping Containers', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openshipping_containers_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('transload_orders', 'icon-datex-OutboundOrder', 'Transload orders', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.opentransload_orders_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
      ]
      ,      
      ($utils: UtilsService) => {},
      () => this.currentMenubarItem = null
    ),
  
    new MenuItemModel('planning', 'icon-ic_fluent_flowchart_20_regular', 'Planning', [
        new MenuItemModel('labor_management_hub', 'ms-Icon ms-Icon--WorkforceManagement', 'Labor Management', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openlabor_management_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('inspections', 'icon-ic_fluent_box_multiple_search_20_regular', 'Inspections', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openinspections_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
      ]
      ,      
      ($utils: UtilsService) => {},
      () => this.currentMenubarItem = null
    ),
  
    new MenuItemModel('inventory', 'icon-ic_fluent_apps_20_regular', 'Inventory', [
        new MenuItemModel('inventory', 'ms-Icon ms-Icon--AddIn', 'Inventory', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openinventory_hub(
            {
              ownerId:  null ,
              projectId:  null ,
              warehouseId:  null 
            },
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('activity_hub', 'icon-ic_fluent_tasks_app_20_regular', 'Activity', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openactivity_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('lots', 'icon-ic_fluent_preview_link_20_regular', 'Lots', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openlots_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('serialnumbers', 'icon-ic_fluent_text_bullet_list_tree_20_regular', 'Serial Numbers', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openserialnumbers_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('inventory_transfers_hub', 'icon-ic_fluent_box_multiple_arrow_right_20_regular', 'Inventory Transfers', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openinventory_transfers_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('inventory_count', 'icon-ic_fluent_clipboard_number_123_20_regular', 'Inventory Counts', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openinventory_counts_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('replenishment', 'icon-ic_fluent_cube_sync_20_regular', 'Replenishments', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openreplenishment_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('warehouses', 'icon-ic_fluent_scale_fill_20_regular', 'Warehouses', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openwarehouses_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
      ]
      ,      
      ($utils: UtilsService) => {},
      () => this.currentMenubarItem = null
    ),
  
    new MenuItemModel('owners', 'icon-ic_fluent_video_person_20_regular', 'Owners', [
        new MenuItemModel('owners', 'ms-Icon ms-Icon--Group', 'Owners', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openowners_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('projects', 'icon-ic_fluent_person_edit_20_regular', 'Projects', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openprojects_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('materials', 'icon-ic_fluent_apps_list_20_regular', 'Materials', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openmaterials_hub(
            {
              ownerId:  null ,
              projectId:  null 
            },
            false)},
            () => this.currentMenubarItem = null
      ),
      ]
      ,      
      ($utils: UtilsService) => {},
      () => this.currentMenubarItem = null
    ),
  
    new MenuItemModel('billing', 'icon-ic_fluent_calculator_20_regular', 'Billing', [
        new MenuItemModel('billing_contracts', 'ms-Icon ms-Icon--PenWorkspace', 'Billing Contracts', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openbilling_contracts_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('billing_records', 'icon-ic_fluent_grid_dots_20_regular', 'Billing Records', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openbilling_records_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('invoices', 'icon-ic_fluent_receipt_20_regular', 'Invoices', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openinvoices_hub(
            {
              ownerId:  null ,
              projectId:  null 
            },
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('work_orders', 'icon-ic_fluent_clipboard_edit_20_regular', 'Work Orders', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openwork_orders_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('auto_invoicing_rules_hub', 'icon-ic_fluent_receipt_money_20_regular', 'Auto-invoicing', [],  ($utils: UtilsService) => {
            this.shell.Invoices.openinvoicing_rules_hub(
            {
              projectId:  null ,
              billingContractId:  null 
            },
            false)},
            () => this.currentMenubarItem = null
      ),
      ]
      ,      
      ($utils: UtilsService) => {},
      () => this.currentMenubarItem = null
    ),
  
    new MenuItemModel('integrations', 'icon-ic_fluent_cloud_swap_20_regular', 'Integrations', [
        new MenuItemModel('entity_import', 'ms-Icon ms-Icon--PAAction', 'Entity Import', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openentity_import_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('footprint_api_manager', 'icon-ic_fluent_cloud_flow_20_regular', 'Footprint Api Manager', [],  ($utils: UtilsService) => {
            this.shell.FootPrintApiManager.openfootprint_api_hub(
            {
              integration_name:  null 
            },
            false)},
            () => this.currentMenubarItem = null
      ),
      ]
      ,      
      ($utils: UtilsService) => {},
      () => this.currentMenubarItem = null
    ),
  
    new MenuItemModel('settings', 'icon-ic_fluent_settings_20_regular', 'Settings', [
        new MenuItemModel('Inventory_configurations', 'ms-Icon ms-Icon--ProductCatalog', 'Inventory configurations', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openconfigurations_inventory_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('storage_categories', 'icon-ic_fluent_group_20_regular', 'Storage categories', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.openconfigurations_storage_categories_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('email_rules', 'icon-ic_fluent_mail_alert_20_regular', 'Email rules', [],  ($utils: UtilsService) => {
            this.shell.Notifications.openauto_email_rules_hub(
            {
              projectId:  null 
            },
            false)},
            () => this.currentMenubarItem = null
      ),
        new MenuItemModel('surveys', 'icon-ic_fluent_clipboard_bullet_list_ltr_20_regular', 'Surveys', [],  ($utils: UtilsService) => {
            this.shell.FootPrintManager.opensurveys_hub(
            false)},
            () => this.currentMenubarItem = null
      ),
      ]
      ,      
      ($utils: UtilsService) => {},
      () => this.currentMenubarItem = null
    ),
  ]

  menubar = { 
      dashboards: this.items.find(i => i.id == 'dashboards') as { hidden: boolean, menubar: {  revenue_dashboard: { hidden: boolean },  lot_management_dashboard: { hidden: boolean },  labor_management_dashboard: { hidden: boolean },  } }, 
      inbound: this.items.find(i => i.id == 'inbound') as { hidden: boolean, menubar: {  inbound_orders: { hidden: boolean },  returns_hub: { hidden: boolean },  } }, 
      outbound: this.items.find(i => i.id == 'outbound') as { hidden: boolean, menubar: {  outbound_orders: { hidden: boolean },  warehouse_transfers: { hidden: boolean },  pack_verification: { hidden: boolean },  waves: { hidden: boolean },  } }, 
      transportation: this.items.find(i => i.id == 'transportation') as { hidden: boolean, menubar: {  dock_appointments: { hidden: boolean },  dock_doors: { hidden: boolean },  driver_check_out: { hidden: boolean },  load_containers: { hidden: boolean },  shipping_containers: { hidden: boolean },  transload_orders: { hidden: boolean },  } }, 
      planning: this.items.find(i => i.id == 'planning') as { hidden: boolean, menubar: {  labor_management_hub: { hidden: boolean },  inspections: { hidden: boolean },  } }, 
      inventory: this.items.find(i => i.id == 'inventory') as { hidden: boolean, menubar: {  inventory: { hidden: boolean },  activity_hub: { hidden: boolean },  lots: { hidden: boolean },  serialnumbers: { hidden: boolean },  inventory_transfers_hub: { hidden: boolean },  inventory_count: { hidden: boolean },  replenishment: { hidden: boolean },  warehouses: { hidden: boolean },  } }, 
      owners: this.items.find(i => i.id == 'owners') as { hidden: boolean, menubar: {  owners: { hidden: boolean },  projects: { hidden: boolean },  materials: { hidden: boolean },  } }, 
      billing: this.items.find(i => i.id == 'billing') as { hidden: boolean, menubar: {  billing_contracts: { hidden: boolean },  billing_records: { hidden: boolean },  invoices: { hidden: boolean },  work_orders: { hidden: boolean },  auto_invoicing_rules_hub: { hidden: boolean },  } }, 
      integrations: this.items.find(i => i.id == 'integrations') as { hidden: boolean, menubar: {  entity_import: { hidden: boolean },  footprint_api_manager: { hidden: boolean },  } }, 
      settings: this.items.find(i => i.id == 'settings') as { hidden: boolean, menubar: {  Inventory_configurations: { hidden: boolean },  storage_categories: { hidden: boolean },  email_rules: { hidden: boolean },  surveys: { hidden: boolean },  } }, 
  };

  crumbs: Crumb[] = [];

  loadingBarProgress: number = 0;
  destroyed$ = new Subject();
  initialized = false;

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    private logger: CleanupLoggerService,

    private location: Location,
    private authService: MsalService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingBarService: LoadingBarService,
    private titleService:Title,
    private datexErrorService: DatexErrorService,
    @Inject(MSAL_INTERCEPTOR_CONFIG) private msalInterceptorConfiguration: MsalInterceptorConfiguration
  ) {
    super();
   }


  ngOnInit(): void {
    this.$init();
  }
  

  async $init() {
    const $workspace = this;
    const $utils = this.utils;

    await this.on_init();

    this.account = this.authService.instance.getActiveAccount();

    this.initialized = true;
  }
  
  ngAfterViewInit(): void {

    this.loadingBarService.value$
    .pipe(takeUntil(this.destroyed$))
    .subscribe(data => this.loadingBarProgress = data);

    this.route.queryParamMap.subscribe(params => {
      const view = this.route.snapshot.paramMap.get('view');

      if (view === 'home') {
        // TODO: ExpressionChangedAfterItHasBeenCheckedError
        Promise.resolve().then(() => this.openHome());
      } else if (view === 'preview') {
        Promise.resolve().then(() => this.openPreview(params));
      } else {
        const info = this.shell.getComponentInformation(view, params);

        if (info) {
          // TODO: ExpressionChangedAfterItHasBeenCheckedError
          Promise.resolve().then(() => this.openView(info.title, view, info.component, false, info.inParams));
        } else {
          const errorMesssage = `Unable to open view [${view}]. Unknown view`;
          console.error(errorMesssage);
          this.datexErrorService.add(new Error(errorMesssage));
          this.router.navigate(['error']);
        }
      }

    });

    ShellService.openViewRequest$.subscribe(request => {
      this.openView(request.title, request.referenceName, request.component, request.replaceCurrentView, request.inParams);
    });

  }

  ngOnDestroy(): void {
    this.clearCrumbs();
    this.destroyed$.next(null);
    this.destroyed$.unsubscribe();
  }

  onHamburgerClicked(): void {
    this.showMainMenu = !this.showMainMenu;
  }

  onLogoutClicked(): void {
    this.authService.logout();
  }

  onCrumbClicked(crumb: Crumb) {
    this.activateCrumb(crumb);
  }

  onOpenHomeClicked() {
    this.currentMenubarItem = null;
    this.openHome();
  }

  onMenubarItemClicked(menubarItem: IMenubarItem) {
    if (menubarItem.items?.length) {
      if (this.currentMenubarItem && this.currentMenubarItem === menubarItem) {
        this.currentMenubarItem = null;
      } else {
        this.currentMenubarItem = menubarItem;
      }
    } else {
      this.currentMenubarItem = null;
      this.clearCrumbs();
      menubarItem.click(this.utils);
    }
  }

  onCloseClicked() {
    this.closeCrumbs(this.currentCrumb);
  }

  private addScript(type: EmbedScriptType, data: string) {
    switch(type){
      case EmbedScriptType.Inline:
        this.addInlineScript(data);
        break;
      case EmbedScriptType.External:
        this.addExternalScript(data);
        break;
      default:
        throw new Error(`Unknown script type ${type}`);
    }
  }

  private addInlineScript(body: string) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = body;
    document.body.appendChild(script);
  }

  private addExternalScript(url: string) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.defer = true;
    document.body.appendChild(script);
  }

  private get currentCrumb() {
    return this.crumbs.find(c => c.active == true);
  }

  private clearCrumbs() {
    this.crumbs.forEach(c => c.destroy());
    this.crumbs.splice(0, this.crumbs.length);
  }

  private activateCrumb(crumb: Crumb, addToHost: boolean = true) {
    this.crumbs.forEach(c => { c.active = false; });
    crumb.active = true;
    let path = this.location.path().split('?')[0];
    path = path.split('/').slice(0, -1).join('/') + `/${crumb.referenceName}`;
    
    this.location.replaceState(path, crumb.queryString);

    if (addToHost) {
      this.hostRef.detach();
      this.hostRef.insert(crumb.componentRef.hostView);
      crumb.componentRef.instance.refresh?.();
    }
  }

  private closeCrumbs(startCrumb: Crumb, autoActivate = true) {
    const index = this.crumbs.findIndex(c => c === startCrumb);
     // delete everything from the right
    const deletedCrumbs = this.crumbs.splice(index, this.crumbs.length - index);
    if (deletedCrumbs) {
      deletedCrumbs.forEach(c => c.destroy());
    }

    if (autoActivate) {
      if (this.crumbs.length === 0) {
        this.openHome();
      } else {
        this.activateCrumb(this.crumbs[this.crumbs.length - 1]);
      }
    }
  }

  private openHome() {
    this.clearCrumbs();
    
    this.shell.FootPrintManager.openorders_hub(
      false
    );
  }

  private openPreview(params: ParamMap) {
  }

  private openView(title: string, referenceName: string, component: any, replaceCurrentView?: boolean, inParams?: any): ComponentRef<any> {

    if (this.currentCrumb) {
      if (replaceCurrentView) {
        // Close current view without trying to activate previous blade or home
        this.closeCrumbs(this.currentCrumb, false);
      } else {
        // This closes the view next to the current view
        // so that it open the new view next to the current view
        const index = this.crumbs.findIndex(c => c === this.currentCrumb);
        const nextCrumb = this.crumbs[index + 1];
        if (nextCrumb) {
          this.closeCrumbs(nextCrumb, false);
        }
      }
    }

    this.hostRef.detach();
    let closeToolRef;
    let componentRef;

    if (this.crumbs.length > 0) {
      // create close button view
      closeToolRef = this.closeToolTmpRef.createEmbeddedView(null);

      // create the component and project the closetool view
      componentRef = this.hostRef.createComponent(component, {
        projectableNodes: [closeToolRef.rootNodes]
      });
    } else {
      // create the component without closetool
      componentRef = this.hostRef.createComponent(component, {
      });
    }

    // NOTE: because we are dynamically creating components
    // and setting the inputs programmatically, 
    // ngOnChanges of the component will not be called
    // if we wishes to do so, we can either have the component
    // inputs call detectchanges or call componentRef.hostView.detectChanges here
    // but probably don't need for this use case

    // set component inputs
    if (!isNil(inParams)) {
      Object.keys(inParams).forEach(k => {
        componentRef.instance['$inParams_'+k] = inParams[k];
      });
    }

    const crumb = new Crumb(title, referenceName, componentRef, closeToolRef, inParams);
    this.crumbs.push(crumb);
    this.activateCrumb(crumb, false);
    
    // From flow you call close(), it will emit $finish
    // so shell can close the view
    if (componentRef.instance['$finish']) {
      componentRef.instance['$finish']
      .pipe(
        // Just an easy way to unsub from subscription
        takeUntil(crumb.destroyed$)
      )
      .subscribe(() => {
        // TODO: There is an issue with replace view
        // if it is the only view, it will close, open the home 
        // then open the view that replace the current one
        this.closeCrumbs(crumb);
      });
    }
    
    if (componentRef.instance['$titleChange']) {
      componentRef.instance['$titleChange']
      .pipe(
        // Just an easy way to unsub from subscription
        takeUntil(crumb.destroyed$)
      )
      .subscribe(title => {
        crumb.title = title;
      });
    }

    return componentRef;
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $workspace: ShellComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  // Hide navigation items
  
  // Inbound
  if (await $operations.FootPrintManager.Disable_Navigation_Inbound.isAuthorized()) {
      $workspace.menubar.inbound.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Inbound_Returns.isAuthorized()) {
      $workspace.menubar.inbound.menubar.returns_hub.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Inbound_Orders_Hub.isAuthorized()) {
      $workspace.menubar.inbound.menubar.inbound_orders.hidden = true;
  }
  
  
  
  // Outbound
  if (await $operations.FootPrintManager.Disable_Navigation_Outbound.isAuthorized()) {
      $workspace.menubar.outbound.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Outbound_Orders_Hub.isAuthorized()) {
      $workspace.menubar.outbound.menubar.outbound_orders.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Outbound_Pack_Verification.isAuthorized()) {
      $workspace.menubar.outbound.menubar.pack_verification.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Outbound_Waves.isAuthorized()) {
      $workspace.menubar.outbound.menubar.waves.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Outbound_Outbound_Warehouse_Transfers.isAuthorized()) {
      $workspace.menubar.outbound.menubar.warehouse_transfers.hidden = true;
  }
  
  // Trasportation
  if (await $operations.FootPrintManager.Disable_Navigation_Transportation.isAuthorized()) {
      $workspace.menubar.transportation.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Transportation_Dock_Appointments.isAuthorized()) {
      $workspace.menubar.transportation.menubar.dock_appointments.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Transportation_Dock_Doors.isAuthorized()) {
      $workspace.menubar.transportation.menubar.dock_doors.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Transportation_Driver_Check_Out.isAuthorized()) {
      $workspace.menubar.transportation.menubar.driver_check_out.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Transportation_Load_Containers.isAuthorized()) {
      $workspace.menubar.transportation.menubar.load_containers.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Transportation_Shipping_Containers.isAuthorized()) {
      $workspace.menubar.transportation.menubar.shipping_containers.hidden = true;
  }
  
  // Planning
  if (await $operations.FootPrintManager.Disable_Navigation_Planning.isAuthorized()) {
      $workspace.menubar.planning.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Planning_Inspections.isAuthorized()) {
      $workspace.menubar.planning.menubar.inspections.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Planning_Labor_Management.isAuthorized()) {
      $workspace.menubar.planning.menubar.labor_management_hub.hidden = true;
  }
  
  
  // Inventory
  if (await $operations.FootPrintManager.Disable_Navigation_Inventory.isAuthorized()) {
      $workspace.menubar.inventory.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Inventory_Inventory_Hub.isAuthorized()) {
      $workspace.menubar.inventory.menubar.inventory.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Inventory_Inventory_Count.isAuthorized()) {
      $workspace.menubar.inventory.menubar.inventory_count.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Inventory_Inventory_Transfers.isAuthorized()) {
      $workspace.menubar.inventory.menubar.inventory_transfers_hub.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Inventory_Lots.isAuthorized()) {
      $workspace.menubar.inventory.menubar.lots.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Inventory_Replenishment.isAuthorized()) {
      $workspace.menubar.inventory.menubar.replenishment.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Inventory_Serial_Numbers.isAuthorized()) {
      $workspace.menubar.inventory.menubar.serialnumbers.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Inventory_Warehouses.isAuthorized()) {
      $workspace.menubar.inventory.menubar.warehouses.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Inventory_Activity.isAuthorized()) {
      $workspace.menubar.inventory.menubar.activity_hub.hidden = true;
  }
  
  
  // Owners
  if (await $operations.FootPrintManager.Disable_Navigation_Owners.isAuthorized()) {
      $workspace.menubar.owners.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Owners_Owners_Hub.isAuthorized()) {
      $workspace.menubar.owners.menubar.owners.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Owners_Materials.isAuthorized()) {
      $workspace.menubar.owners.menubar.materials.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Owners_Projects.isAuthorized()) {
      $workspace.menubar.owners.menubar.projects.hidden = true;
  }
  
  // Billing
  if (await $operations.FootPrintManager.Disable_Navigation_Billing.isAuthorized()) {
      $workspace.menubar.billing.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Billing_Billing_Contracts.isAuthorized()) {
      $workspace.menubar.billing.menubar.billing_contracts.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Billing_Billing_Records.isAuthorized()) {
      $workspace.menubar.billing.menubar.billing_records.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Billing_Invoices.isAuthorized()) {
      $workspace.menubar.billing.menubar.invoices.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Billing_Work_Orders.isAuthorized()) {
      $workspace.menubar.billing.menubar.work_orders.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Billing_Auto_Invoicing.isAuthorized()) {
      $workspace.menubar.billing.menubar.auto_invoicing_rules_hub.hidden = true;
  }
  
  // Integrations
  if (await $operations.FootPrintManager.Disable_Navigation_Integrations.isAuthorized()) {
      $workspace.menubar.integrations.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Integrations_Entity_Import.isAuthorized()) {
      $workspace.menubar.integrations.menubar.entity_import.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Integrations_Footprint_Api_Manager.isAuthorized()) {
     // Change once api manager is refactored into utilities
     // $workspace.menubar.integrations.menubar.footprint_api_manager.hidden = true;
  }
  
  // Settings
  if (await $operations.FootPrintManager.Disable_Navigation_Settings.isAuthorized()) {
      $workspace.menubar.settings.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Settings_Inventory_Configurations.isAuthorized()) {
      $workspace.menubar.settings.menubar.Inventory_configurations.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Settings_Storage_Categories.isAuthorized()) {
      $workspace.menubar.settings.menubar.storage_categories.hidden = true;
  }
  if (await $operations.FootPrintManager.Disable_Navigation_Settings_Surveys.isAuthorized()) {
      $workspace.menubar.settings.menubar.surveys.hidden = true;
  }
  
  
  
  
  //Usersnap Widget
  if (await $operations.FootPrintManager.Disable_Navigation_UserSnap.isAuthorized()) {
      // Do nothing
  }
  else {
      let usersnapContent = (await $flows.Usersnap.get_usersnap_widget({})).usersnap_scriptContent;
      $workspace.addScript(EmbedScriptType.Inline, usersnapContent);
  }
  // Document 360 Help Widget
  if (await $operations.FootPrintManager.Disable_Navigation_Document360.isAuthorized()) {
      // Do nothing
  }
  else {
     let doc360Content = (await $flows.Document360.get_document360_widget({})).doc30_scriptContent;
     $workspace.addScript(EmbedScriptType.Inline, doc360Content);
  }
  
  
  
  }
  //#endregion private flows
}
