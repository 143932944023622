import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { Addresses_DatasourceService } from './Addresses.datasource.index';
import { Carriers_DatasourceService } from './Carriers.datasource.index';
import { Instructions_DatasourceService } from './Instructions.datasource.index';
import { Materials_DatasourceService } from './Materials.datasource.index';
import { PurchaseOrders_DatasourceService } from './PurchaseOrders.datasource.index';
import { DockAppointments_DatasourceService } from './DockAppointments.datasource.index';
import { Owners_DatasourceService } from './Owners.datasource.index';
import { Discussions_DatasourceService } from './Discussions.datasource.index';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { SalesOrders_DatasourceService } from './SalesOrders.datasource.index';
import { Document360_DatasourceService } from './Document360.datasource.index';
import { Usersnap_DatasourceService } from './Usersnap.datasource.index';
import { Locations_DatasourceService } from './Locations.datasource.index';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { FootPrintApiManager_DatasourceService } from './FootPrintApiManager.datasource.index';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';


@Injectable({ providedIn: 'root' })
export class app_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
    this.Addresses = this.injector.get(Addresses_DatasourceService);
    this.Carriers = this.injector.get(Carriers_DatasourceService);
    this.Instructions = this.injector.get(Instructions_DatasourceService);
    this.Materials = this.injector.get(Materials_DatasourceService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_DatasourceService);
    this.DockAppointments = this.injector.get(DockAppointments_DatasourceService);
    this.Owners = this.injector.get(Owners_DatasourceService);
    this.Discussions = this.injector.get(Discussions_DatasourceService);
    this.Notifications = this.injector.get(Notifications_DatasourceService);
    this.SalesOrders = this.injector.get(SalesOrders_DatasourceService);
    this.Document360 = this.injector.get(Document360_DatasourceService);
    this.Usersnap = this.injector.get(Usersnap_DatasourceService);
    this.Locations = this.injector.get(Locations_DatasourceService);
    this.Invoices = this.injector.get(Invoices_DatasourceService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_DatasourceService);
    this.FootPrintManager = this.injector.get(FootPrintManager_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
    public Addresses: Addresses_DatasourceService;
    public Carriers: Carriers_DatasourceService;
    public Instructions: Instructions_DatasourceService;
    public Materials: Materials_DatasourceService;
    public PurchaseOrders: PurchaseOrders_DatasourceService;
    public DockAppointments: DockAppointments_DatasourceService;
    public Owners: Owners_DatasourceService;
    public Discussions: Discussions_DatasourceService;
    public Notifications: Notifications_DatasourceService;
    public SalesOrders: SalesOrders_DatasourceService;
    public Document360: Document360_DatasourceService;
    public Usersnap: Usersnap_DatasourceService;
    public Locations: Locations_DatasourceService;
    public Invoices: Invoices_DatasourceService;
    public FootPrintApiManager: FootPrintApiManager_DatasourceService;
    public FootPrintManager: FootPrintManager_DatasourceService;
  public app: app_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
}

